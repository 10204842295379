import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import "tachyons/css/tachyons.min.css";
import "../css/common.css";
import "../css/pages/special-freight.css";

import Header from "../components/Header";
import SectionTextPage from "../components/ui/SectionTextPage";
import Footer from "../components/Footer";
import Seo from "../components/SeoComponent";
import Contact from "../components/Contact";

/**
 * Display the input form contact
 * @param string id : Tag id
 * @param string [type] : Type of input ( text, email, password, etc.. ) - Default text - Special type : textarea => display a textarea instead an input
 * @param string name : Tag name
 * @param string label : Label of input
 */
const ContactPageFormInput = props => {
  const classInput = "pa2 input-reset ba bg-white w-100";
  const inpuType = props.type ?? "text";
  let inputForm = <input className={classInput} type={inpuType} name={props.namme} id={props.id} />;
  if (inpuType === "texarea") {
    inputForm = <textarea className={classInput} rows="6" name={props.namme} id={props.id}></textarea>;
  }

  return (
    <div className="mv3">
      <label className="db fw6 lh-copy f6 mb2" htmlFor={props.id}>
        {props.label}
      </label>
      {inputForm}
    </div>
  );
};

const ContactPage = () => {
  const { t } = useTranslation();

  const validFormHandler = e => {
    e.preventDefault();
    console.log("validFormHandler");
  };

  return (
    <>
      <Seo title={t("contact.metadata.name")} description={t("contact.metadata.description")} />
      <Header />
      <SectionTextPage className="page-container">
        <Contact />
        {/* <TitlePage title={t("contact.title")} style={{ paddingLeft: 0 }} /> */}
      </SectionTextPage>

      {/* <SectionTextPage mode="dark">
        <form className="w-100 measure center" onSubmit={validFormHandler}>
          <fieldset id="contact" className="ba b--transparent ph0 mh0">
            <legend className="f4 fw6 ph0 mh0">Send a message</legend>
            <ContactPageFormInput name="ctc-name" id="form-ctc-name" label="name (fr)" />
            <ContactPageFormInput name="email-address" id="form-email" type="email" label="Email" />
            <ContactPageFormInput name="ctc-object" id="form-ctc-object" label="object (fr)" />
            <ContactPageFormInput name="ctc-msg" id="form-ctc-msg" label="Message (fr)" type="texarea" />
          </fieldset>
          <div className="">
            <input
              className="b ph3 pv2 input-reset ba b--white bg-transparent white grow pointer f6 dib"
              type="submit"
              value="Send"
            />
          </div>
          <div className="lh-copy mt3">
            <a href="#0" className="f6 link dim black db">
              Sign up
            </a>
            <a href="#0" className="f6 link dim black db">
              Forgot your password?
            </a>
          </div>
        </form>
      </SectionTextPage> */}

      <Footer />
    </>
  );
};

export default ContactPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
